import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography, Button, Icon } from '@material-ui/core'
import Page from '../components/page'
import withRoot from '../withRoot'
import { navigate } from 'gatsby'

const styles = theme => ({
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  error: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  }
})

@withRoot
@withStyles(styles)
export default class NotFoundPage extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <Page toggleTheme={this.props.toggleTheme} seo="404">
        <Typography className={classes.error} align="center" color="secondary" variant="h1">404</Typography>
        <Typography className={classes.error} align="center">
          Looks like you’ve taken a wrong turn
        </Typography>
        <Typography className={classes.error} align="center">
          <Button onClick={() => (navigate("/"))} color="primary" size="small"><Icon className={classes.leftIcon}>home</Icon>Go back to home</Button>
        </Typography>
      </Page>
    )
  }
}
